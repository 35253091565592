import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { useDispatch, useQueryParams, useSelector } from 'services/hooks';
import { selectSiteData, selectActiveSiteData } from 'selectors';
import { getClasses } from 'ducks/classes/list';

import { Container, DayButton, DayText, DateText } from './styled';

export const MobileBookingDayselector:React.FC<MobileBookingDaySelector> = ({ days }) => {
  const dispatch = useDispatch();
  const { setQueryParams, queryParams } = useQueryParams();
  const activeSite = useSelector(selectActiveSiteData);
  const activeDay = queryParams.date;
  const sites = useSelector(selectSiteData);

  const setActiveDate = (day: Dayjs) => {
    const selectedDay = dayjs(day).format('YYYY-MM-DD');
    const site = activeSite || sites[0]?.city;

    setQueryParams({ ...queryParams, date: [selectedDay] });
    dispatch(getClasses(site, selectedDay));
  };


  return (
    <Container>
      {days.map((day, i) => {
        const isActive = dayjs(day).isSame(activeDay, 'day');

        return (
          <DayButton
            onClick={() => setActiveDate(day)}
            isActive={isActive}
          >
            <DayText
              $uppercase
              $weight={700}
              $size={12}
              isActive={isActive}
            >
              {dayjs(day).isSame(new Date(), 'day') ? 'Today' : dayjs(day).format('ddd')}
            </DayText>
            <DateText
              $weight={700}
              $size={12}
              isActive={isActive}
            >
              {dayjs(day).format('DD MMM')}
            </DateText>
          </DayButton>
        );
      })}
    </Container>
  );
};

type MobileBookingDaySelector = {
  days: Dayjs[];
};

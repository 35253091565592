import * as React from 'react';

import { getSeries } from 'ducks/series';
import { getSites, sitesActions } from 'ducks/sites';
import { getClasses } from 'ducks/classes/list';
import { selectActiveSiteData } from 'selectors/sites';
import { getLocalData, formatDateRaw } from 'services';
import { useAuthenticationUser, useDispatch, useQueryParams, useSelector } from 'services/hooks';

import { BookingHeader, BookingContent } from './components';

const BookingPage = () => {
  const dispatch = useDispatch();
  const { queryParams, setQueryParams } = useQueryParams();
  const { determined } = useAuthenticationUser();
  const activeSite = useSelector(selectActiveSiteData);
  const today = formatDateRaw(Date.now());

  const fetchClasses = async () => {
    if (!activeSite) {
      const localSite = getLocalData('user::activeSite');
      const sites = await dispatch(getSites());

      dispatch(sitesActions.setActiveSite(localSite || sites[0]?.city));
    } else {
      const date = queryParams?.date || today;
      dispatch(getClasses(activeSite, date));
      dispatch(getSeries(activeSite));
    }
  };

  React.useEffect(() => {
    if (!queryParams) {
      setQueryParams({ date: [today] });
    }
  }, []);

  React.useEffect(() => {
    if (determined) {
      fetchClasses();
    }
  }, [determined, activeSite]);

  return (
    <>
      <BookingHeader />
      <BookingContent />
    </>
  );
};

export default BookingPage;

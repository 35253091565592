import * as i from 'types';
import * as React from 'react';

import { Text } from 'common/typography';

import {
  FilterSectionContainer, FilterButton, FilterButtonWrapper, FilterSectionHeader,
} from './styled';

export const BookingFilterSection:React.FC<BookingFilterSectionProps> = ({
  type, title, filterOptions, selectFilterOption, activeOptions,
}) => {
  if (filterOptions.length === 0) return null;

  return (
    <FilterSectionContainer>
      <FilterSectionHeader>
        <Text
          $uppercase
          $weight={700}
          $size={16}
          $lineHeight="24px"
        >
          {title}
        </Text>
      </FilterSectionHeader>
      <FilterButtonWrapper>
        {filterOptions.map((option) => (
          <FilterButton
            key={option.id}
            onClick={() => selectFilterOption(type, option.id)}
            isActive={Boolean(activeOptions?.includes(option.id))}
          >
            {option.name}
          </FilterButton>
        ))}
      </FilterButtonWrapper>
    </FilterSectionContainer>
  );
};

type FilterOption = {
  id: string;
  name: string;
};

type BookingFilterSectionProps = {
  title: string;
  activeOptions?: string[];
  filterOptions: FilterOption[];
  type: i.FilterKeyEnum;
  selectFilterOption: (type: keyof i.ActiveClassesFilterRecord, value: string) => void;
};

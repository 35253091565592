import * as i from 'types';
import * as React from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { Text, Tag } from 'common/typography';
import { getExpiryLabel } from 'services';
import { useAuthenticationUser } from 'services/hooks';
import FirstTimerBanner from 'images/firstTimerBanner.jpg';

import {
  FirstTimerContainer,
  ContainerBackgroundImage,
  ContentContainer,
  TitleContainer,
  ButtonContainer,
  ExtendedButton,
  HeaderContainer,
  ExtendedHeading,
} from './styled';

export const FirstTimeClassBooking: React.FC<FirstTimeClassProps> = ({ item }) => {
  const expiryLabel = getExpiryLabel(item.expirationInterval, item.expiresIn);
  const { authenticated } = useAuthenticationUser();
  const { t } = useTranslation();
  const { navigate } = useI18next();

  const handleRedirect = () => {
    if (!authenticated) {
      navigate('/register',
        {
          state: {
            redirectUrl: '/pricing',
          },
        });
    } else {
      navigate('/pricing');
    }
  };

  return (
    <FirstTimerContainer>
      <ContainerBackgroundImage src={FirstTimerBanner} alt="first timer" />
      <ContentContainer>
        <HeaderContainer>
          <Tag item="first-timers only" />
        </HeaderContainer>
        <TitleContainer>
          <ExtendedHeading margin="0" upperCase>{item?.name}</ExtendedHeading>
          <Text variant="white" $lineHeight="22">{t('valid for')} {expiryLabel}</Text>
        </TitleContainer>
        <ButtonContainer>
          <ExtendedButton
            size="fullWidth"
            onClick={() => handleRedirect()}
          >
            {t('buy now')}
          </ExtendedButton>
        </ButtonContainer>
      </ContentContainer>
    </FirstTimerContainer>
  );
};

type FirstTimeClassProps = {
  item: i.Serie;
}

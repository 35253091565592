import styled from 'styled-components';

import { media } from 'styles/utils';

export const OverflowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: calc(80vh - 125px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    border-radius: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.white.opacity};
    border-radius: 4px;
  }
`;

export const PageWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray.light};
`;

export const Container = styled.div`
  padding: 0 24px;

  ${media.tablet`
    padding: 0 24px;
  `}

  ${media.desktop`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    max-width: 1440px;
    margin: 0 auto;
  `}
`;

export const BookingCardListWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 0;

  & > a {
    text-decoration: none;
  }

  ${media.desktop`
    grid-column: 6 / 13;
    margin-top: 20px;
    padding: 24px 0px;
  `}
`;

export const HorizontalLine = styled.div`
  border-radius: 4px;
  width: 100%;
  opacity: 0.5;
  border: 1px solid ${({ theme }) => theme.colors.gray.medium};
  margin-bottom: 8px;
`;

export const EmptyStateContainer = styled.div`
  padding-top: 64px;
`;

import styled from 'styled-components';

import { Heading } from 'common/typography';
import { Button } from 'common/interaction';
import { media } from 'styles/utils';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CtaContainer = styled.div`
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  max-height: 263px;
  color: ${({ theme }) => theme.colors.white};
`;

export const ContainerBackgroundImage = styled.img`
  object-fit: cover;
  height: 200px;
  min-height: 25vh;
  width: 100%;
  border-radius: 4px;

  ${media.desktop`
    height: 263px;
  `}
`;

export const TitleContainer = styled.div`
  padding: 10px 16px;

  ${media.desktop`
    padding: 24px 24px 10px 24px;
  `}
`;

export const ExtendedHeading = styled(Heading)`
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
`;

export const ButtonContainer = styled.div`
  padding: 0 16px 24px;

  ${media.desktop`
    width: 50%;
    padding: 0 16px 30px 24px;
  `}
`;

export const ExtendedButton = styled(Button)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.white};
`;

import styled from 'styled-components';
import { motion } from 'framer-motion';
import { media } from 'styles/utils';

import { Button } from 'common/interaction';
import Close from 'vectors/Close.svg';

export const FilterContainer = styled(motion.div)`
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: scroll;
  padding: 0 24px;

  ${media.desktop`
    height: 100vh;
    width: 40vw;
    padding: 0 88px;
  `}
`;

export const Header = styled.div`
  margin: 100px 0 32px;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  opacity: 0.3;
  border-top: 1px solid ${({ theme }) => theme.colors.gray.medium};
  margin-bottom: 16px;
`;

export const IconContainer = styled.div`
  padding: 8px;
  position: fixed;
  top: 48px;
  right: 48px;
`;

export const CloseIcon = styled(Close)`
  height: 16px;
  width: 16px;

  &:hover {
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  margin-bottom: 50px;

  ${media.desktop`
    justify-content: space-evenly;
    flex: 0 0 125px;
    margin-bottom: 100px;
  `}
`;

export const ExtendedButton = styled(Button)`
  max-width: 180px;

  ${media.desktop`
    max-width: 216px;
  `}
`;

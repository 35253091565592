import * as React from 'react';
import { graphql } from 'gatsby';

import { Template } from 'common/layout';
import BookingPage from 'modules/BookingPage';

const BookingOverview = () => {
  return (
    <Template>
      <BookingPage />
    </Template>
  );
};

export default BookingOverview;

export const query = graphql`
  query Booking($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          language
          data
        }
      }
    }
  }
`;

import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { useAuthenticationUser, useSelector } from 'services/hooks';
import { api } from 'services/api';
import { copyToClipboard } from 'services';
import { selectActiveContract } from 'selectors';
import { Text } from 'common/typography';
import FirstTimerBanner from 'images/firstTimerBanner.jpg';
import InviteAFriendBanner from 'images/friendReferrer.jpg';
import ShareCodeBanner from 'images/personalTraining.png';

import { CtaContainer, ContainerBackgroundImage, ExtendedHeading, TitleContainer, ButtonContainer,
  ExtendedButton, ContentContainer } from './styled';

export const GreetingBlockCta:React.FC<GreetingBlockCtaProps> = ({ discountCode }) => {
  const { t } = useTranslation();
  const [referralId, setReferralId] = React.useState('');
  const { authenticated, determined } = useAuthenticationUser();
  const isActiveContract = useSelector(selectActiveContract);

  React.useEffect(() => {
    if (determined && authenticated) {
      api.get({ path: '/proxy/zingfit/account/referrals/referralid' })
        .then((res) => {
          setReferralId(res?.referralId);
        });
    }
  }, [determined]);

  const copyReferralToClipboard = () => {
    const text = `Hey you! Friends who sweat together, stay together right? 💫 Become a Gold member now at Saints & Stars by using my personal referral link https://saints-stars.com/en/register?referral=${referralId} and discount code FRIENDS20, and we'll both get a €20 discount!`;

    copyToClipboard(text)
      .then(() => {
        alert('The referral link is copied to your clipboard. Paste it anywhere to share it with your friends.');
      })
      .catch((err) => {
        alert('something went wrong, please try again later');
        console.error(err);
      });
  };

  const copyDiscountcodeToClipboard = () => {
    const text = 'Hey you! Wanna claim your first Holy workout at Saints & Stars for only €10? 💫 Register now, use my personal discount code DBC7C5 and let\'s sweat together! https://saints-stars.com/en/register';

    copyToClipboard(text)
      .then(() => {
        alert('The discount code is copied to your clipboard. Paste it anywhere to share it with your friends!');
      })
      .catch((err) => {
        alert('something went wrong, please try again later');
        console.error(err);
      });
  };

  return (
    <>
      {!authenticated && (
        <CtaContainer>
          <ContainerBackgroundImage src={InviteAFriendBanner} alt="Invite a friend" />
          <ContentContainer>
            <TitleContainer>
              <ExtendedHeading margin="0" upperCase>{t('Get in on the action')}</ExtendedHeading>
              <Text $lineHeight="22">{t('Register or log in')}</Text>
            </TitleContainer>
            <ButtonContainer>
              <ExtendedButton
                size="fullWidth"
                to="/register"
              >
                {t('register')}
              </ExtendedButton>
            </ButtonContainer>
          </ContentContainer>
        </CtaContainer>
      )}

      {authenticated && !isActiveContract && (
        <CtaContainer>
          <ContainerBackgroundImage src={ShareCodeBanner} alt="share discount code" />
          <ContentContainer>
            <TitleContainer>
              <ExtendedHeading margin="0" upperCase>{t('Share the fun')}</ExtendedHeading>
              <Text $lineHeight="22">{t('Share unique discount')}</Text>
            </TitleContainer>
            <ButtonContainer>
              <ExtendedButton
                size="fullWidth"
                onClick={() => copyDiscountcodeToClipboard()}
              >
                {t('Copy code')}
              </ExtendedButton>
            </ButtonContainer>
          </ContentContainer>
        </CtaContainer>
      )}

      {authenticated && isActiveContract && (
        <CtaContainer>
          <ContainerBackgroundImage src={FirstTimerBanner} alt="share discount code" />
          <ContentContainer>
            <TitleContainer>
              <ExtendedHeading margin="0" upperCase>{t('Give 20 get 20')}</ExtendedHeading>
              <Text $lineHeight="22">{t('Share your unique url')}</Text>
            </TitleContainer>
            <ButtonContainer>
              <ExtendedButton
                size="fullWidth"
                onClick={() => copyReferralToClipboard()}
              >
                {t('Copy unique code')}
              </ExtendedButton>
            </ButtonContainer>
          </ContentContainer>
        </CtaContainer>
      )}
    </>
  );
};

type GreetingBlockCtaProps = {
  discountCode: string;
};

import styled, { css } from 'styled-components';

import { Text } from 'common/typography';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  overflow-x: auto;
  padding: 0 25px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const DayButton = styled.div<DayButtonProps>`
  min-width: 75px;
  height: 45px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray.light};
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding: 2px;

  ${({ isActive }) => isActive && css`
    background-color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.white};
  `}
`;

export const DayText = styled(Text) <DayButtonProps>`
  color: ${({ theme }) => theme.colors.black};

  ${({ isActive }) => isActive && css`
    color: ${({ theme }) => theme.colors.white};
  `}
`;

export const DateText = styled(Text) <DayButtonProps>`
  color: ${({ theme }) => theme.colors.gray.medium};

  ${({ isActive }) => isActive && css`
    color: ${({ theme }) => theme.colors.white};
  `}
`;

type DayButtonProps = {
  isActive: boolean;
};

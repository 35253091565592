import styled from 'styled-components';

import { media } from 'styles/utils';

export const GreetingBlockWrapper = styled.div`
  padding-top: 50px;
  grid-column: 1/5;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.desktop`
    padding-top: 65px;
  `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Span = styled.span`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  padding: 2px;
`;

export const HorizontalLine = styled.div`
  border-radius: 4px;
  width: 100%;
  opacity: 0.5;
  border: 1px solid ${({ theme }) => theme.colors.gray.medium};
  margin-bottom: 8px;
`;
